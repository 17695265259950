import { css } from '@emotion/react';

export default css`
  .card {
    --card-padding-block: 1.5rem;
    --card-padding-inline: 1rem;

    background: var(--bds-color-white);
    box-shadow: var(--bds-shadow-base);
    padding: var(--card-padding-block) var(--card-padding-inline);
  }

  .card .full-width-section {
    margin-inline: calc(-1 * var(--card-padding-inline));
    padding-inline: var(--card-padding-inline);
  }

  /* 👇 box-shadow goes away in high-contrast mode */
  @media (-ms-high-contrast: active) {
    .card {
      border: 1px solid;
    }
  }
`;
