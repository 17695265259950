import IconEmail from '@iabbb/icons/Email';
import Button from '@iabbb/bds-react/Button';
import clsx from 'clsx';
import React, { useMemo } from 'react';

import { useContactDialog } from './DialogContactForm/context';

export type EmailAddressProps = {
  email: string;
  emailLabel?: string;
  showIcon?: boolean;
  showPR?: boolean;
  DialogProps?: any;
};

function EmailAddress(props: EmailAddressProps & React.ComponentPropsWithoutRef<'span'>) {
  const dialog = useContactDialog();

  const {
    email,
    showPR,
    emailLabel = showPR ? 'Email BBB PR' : 'Email this BBB',
    showIcon = true,
    DialogProps = {},
    ...additionalProps
  } = props;

  const _emailLabel = useMemo(
    () => (DialogProps.entityType ? `Email this ${DialogProps.entityType}` : emailLabel),
    [DialogProps.entityType, emailLabel],
  );

  const handleEmailButtonClick = () => {
    dialog.dispatch({
      type: 'open',
      props: {
        toEmail: email,
        ...DialogProps,
      },
    });
  };

  if (!email) {
    return null;
  }

  return (
    <Button onClick={handleEmailButtonClick} className={clsx({ 'with-icon': showIcon })} variant="unstyled">
      {showIcon && <IconEmail />}
      <span {...additionalProps}>{_emailLabel}</span>
    </Button>
  );
}

export default React.memo(EmailAddress);
