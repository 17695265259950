/**
 * History listener update
 * @param {Object} update History update
 * @param {string} update.action History update action
 * @param {string} update.location History update location, NOT the window.location
 */
const historyListener = () => {
  // If URL changes (e.g. for search page), we want to refresh all ads on that page
  if (typeof window !== 'undefined') {
    // Ensure pubads function is defined/ready before attempting to execute it
    if (window.googletag?.apiReady && window.googletag.pubadsReady) {
      window.googletag.pubads().refresh();
    }
  }
};

export default historyListener;
