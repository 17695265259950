import { produce } from 'immer';

import { DEBUG } from '@iabbb/shared/components/General/Debugger/actions';

const dig = (obj, ks) => (ks.length ? dig(obj[ks[0]], ks.slice(1)) : obj);

export default produce((draft, action) => {
  let keys;
  let lastKey;

  switch (action.type) {
    case DEBUG:
      keys = action.state.split('.');
      lastKey = keys.pop();
      dig(draft, keys)[lastKey] = action.data;
      break;
    default:
  }
}, {});
