import BaselineStyles from '@iabbb/shared/styles/BaselineStyles';
import { FeatureFlagProvider } from '@iabbb/context/FeatureFlag';
import { ReduxProvider } from '@iabbb/context/Redux';
import { NavigatorProvider } from '@iabbb/context/Navigator';
import LoadingIndicator from '@iabbb/shared/components/General/LoadingIndicators/Empty';
import { ContactDialogProvider, DialogContactForm } from '@iabbb/shared/components/General/EmailAddress';
import ErrorFallback from '@iabbb/shared/components/General/ErrorFallback';
import createRootReducer from '@iabbb/utils/routing/createRootReducer';
import historyListener from '@iabbb/utils/browser/historyListener';
import Route from '@iabbb/utils/routing/Route';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import routes from './routes';

import '@iabbb/bds/custom-props.css';
import '@iabbb/bds/cube.css';

interface AppProps {
  featureFlagState: any; // can be null, when request fails/tracking is disabled
  initialState: any;
  href: string;
}

function App({ featureFlagState, initialState, href }: AppProps) {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BaselineStyles />
      <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[href]}>
        <FeatureFlagProvider value={featureFlagState}>
          <NavigatorProvider href={href} listener={historyListener}>
            <ReduxProvider initialState={initialState} createRootReducer={createRootReducer}>
              <ContactDialogProvider>
                <DialogContactForm />
                <Route href={href} routes={routes} />
              </ContactDialogProvider>
            </ReduxProvider>
          </NavigatorProvider>
        </FeatureFlagProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default Sentry.withProfiler(App);
