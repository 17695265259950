import media from '@iabbb/shared/styles/media';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

export default function ErrorFallback({ error }: FallbackProps) {
  useEffect(() => {
    document.title = 'Error | Better Business Bureau&#174;';
  }, []);

  return (
    <>
      <style>
        {`
          main {
            background: linear-gradient(var(--bds-color-blue-medium-8), 90%, rgba(120, 157, 74, 0.6));
            padding-block: 2.375rem var(--bds-spacing-20);
          }
    
          .container {
            --gutter: var(--bds-spacing-20);
            --measure: none;
            display: grid;
            grid-template-areas:
              'error error'
              'art-lead art-assistant';
            gap: clamp(1.25rem, calc(0.86rem + 1.95vw), 2.25rem);
            place-items: center;
          }
    
          .error {
            --bg-color: var(--bds-color-white);
            --border-color: var(--bds-color-gray-60);
    
            background: var(--bg-color);
            border: 1px solid var(--border-color);
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            font-size: var(--bds-font-size-30);
            grid-area: error;
            max-width: 65ch;
            padding-block: clamp(2.5rem, calc(2.16rem + 1.71vw), 3.375rem);
            padding-inline: clamp(1.25rem, calc(0.08rem + 5.85vw), 4.25rem);
            position: relative;
          }
    
          .error .triangle-bottom {
            --height: 2.5rem;
            --width: 2rem;
            bottom: calc(var(--height) * -1);
            color: var(--bg-color);
            height: var(--height);
            left: 25%;
            position: absolute;
            stroke: var(--border-color);
            width: var(--width);
          }
    
          .error .triangle-left {
            display: none;
          }
    
          .error * {
            margin-block: 0;
          }
    
          .error .sting {
            font-weight: bold;
            order: -1;
            text-align: center;
            text-transform: uppercase;
          }
    
          .error h1 {
            margin-block-start: 1.625rem;
          }
    
          .error p:not(.sting) + p {
            margin-block-start: var(--bds-font-size-30);
          }
    
          .art-lead {
            grid-area: art-lead;
            max-width: 100%;
            width: 15rem;
          }
    
          .art-assistant {
            grid-area: art-assistant;
            max-width: 100%;
            width: 15rem;
          }
    
          ${media.sm} {
            .container {
              --measure: 45rem;
            }
          }
    
          ${media.md} {
            .container {
              --measure: 58.75rem;
              grid-template-areas: 'art-lead error';
              grid-template-columns: 1fr 55ch;
            }
    
            .error .triangle-left {
              --height: 2.5rem;
              --width: 3.5rem;
              bottom: auto;
              display: block;
              color: var(--bg-color);
              height: var(--height);
              left: calc(var(--width) * -1);
              position: absolute;
              stroke: var(--border-color);
              top: 10%;
              transform: none;
              width: var(--width);
            }
    
            .error .triangle-bottom {
              display: none;
            }
    
            .art-assistant {
              display: none;
            }
    
            .art-lead {
              width: 20rem;
            }
          }
    
          ${media.lg} {
            .container {
              --measure: 71.25rem;
              grid-template-areas: 'art-lead error art-assistant';
              grid-template-columns: 17.5rem 1fr minmax(auto, 14.625rem);
            }
    
            .art-assistant {
              display: block;
            }
          }
      `}
      </style>
      <main id="content" tabIndex={-1}>
        <div className="container | center">
          <article className="error">
            <svg
              aria-hidden="true"
              className="triangle-left"
              fill="currentColor"
              focusable="false"
              preserveAspectRatio="none"
              viewBox="0 0 100 50"
            >
              <polyline points="100,15 0,10 100,50" strokeWidth="1" vectorEffect="non-scaling-stroke" />
            </svg>
            <svg
              aria-hidden="true"
              className="triangle-bottom"
              fill="currentColor"
              focusable="false"
              preserveAspectRatio="none"
              viewBox="0 0 50 100"
            >
              <polyline points="0,0 40,100 50,0" strokeWidth="1" vectorEffect="non-scaling-stroke" />
            </svg>
            <h1 className="text-size-10">Error</h1>
            <p className="sting">Oops! We'll be right back.</p>

            <p>
              Sorry, something went wrong. Rest assured, we are working hard to fix the problem as soon as
              possible.
            </p>
            <p>
              {'In the meantime, try refreshing this page or visiting our '}
              <a className="font-bold" href={process.env.NEXT_PUBLIC_BASE_URL}>
                homepage
              </a>
              .
            </p>
            {process.env.NODE_ENV !== 'production' && (
              <pre style={{ marginBlockStart: '1rem', overflow: 'auto' }}> {error.message}</pre>
            )}
          </article>
          <img
            alt=""
            className="art-lead"
            src="https://m.bbb.org/terminuscontent/dist/img/500-female-worker.png?tx=w_320"
          />
          <img
            alt=""
            className="art-assistant"
            loading="lazy"
            src="https://m.bbb.org/terminuscontent/dist/img/500-male-worker.png?tx=w_240"
          />
        </div>
      </main>
    </>
  );
}
