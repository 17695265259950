import { COUNTRY_URL_SEGMENT } from '@iabbb/utils/routing/constants';

import Content from './pages/content';
import ContentList from './pages/contentList';

const routes = [
  {
    path: [
      `/${COUNTRY_URL_SEGMENT}/news`,
      `/${COUNTRY_URL_SEGMENT}/events`,
      `/${COUNTRY_URL_SEGMENT}/news-and-events`,
      `/${COUNTRY_URL_SEGMENT}/news/scams`,
      `/${COUNTRY_URL_SEGMENT}/news/:topic`,
      `/${COUNTRY_URL_SEGMENT}/events/:topic`,
      `/${COUNTRY_URL_SEGMENT}/news-and-events/:topic`,
      `/${COUNTRY_URL_SEGMENT}/news/scams/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/news`,
      `/${COUNTRY_URL_SEGMENT}/:state/events`,
      `/${COUNTRY_URL_SEGMENT}/:state/news-and-events`,
      `/${COUNTRY_URL_SEGMENT}/:state/news/scams`,
      `/${COUNTRY_URL_SEGMENT}/:state/news/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/events/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/news-and-events/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/news/scams/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/news`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/events`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/news-and-events`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/news/scams`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/news/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/events/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/news-and-events/:topic`,
      `/${COUNTRY_URL_SEGMENT}/:state/:city/news/scams/:topic`,
    ],
    id: 'contentlist',
    component: ContentList,
  },
  {
    path: ['/content/:code', '/article/:topic/:uri', '/event/:topic/:uri'],
    id: 'content',
    component: Content,
  },
];
export default routes;
