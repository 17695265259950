import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import LoadingSpinner from '../LoadingSpinner';

interface FullPageLoadingSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  height: 100%;
  right: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  z-index: 999999;

  [role='progressbar'] {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

function FullPageLoadingSpinner({ className, ...props }: FullPageLoadingSpinnerProps) {
  return (
    <Overlay className={clsx('text-white', className)} {...props}>
      <LoadingSpinner style={{ '--spinner-size': '4rem' } as React.CSSProperties} />
    </Overlay>
  );
}

export default FullPageLoadingSpinner;
