import React from 'react';
import matchPath from './matchPath';
/**
 * Usings the routes/index.js, switches between components with a nav change.
 * Might be using too much memoization here, but eh
 * TODO: Replace matchPath with own, simpler version
 *
 */
function Route({ href, routes }) {
  const pathname = React.useMemo(() => new URL(href).pathname, [href]);
  const Component = React.useMemo(() => {
    for (let i = 0; i < routes.length; i++) {
      const match = matchPath(pathname, { exact: true, path: routes[i].path });
      if (match) {
        return routes[i].component;
      }
    }
    return null;
  }, [pathname, routes]);

  return <Component />;
}

export default React.memo(Route);
