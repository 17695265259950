import { COUNTRY_URL_SEGMENT } from '@iabbb/utils/routing/constants';

import BbbDirectory from './pages/bbbDirectory';
import BbbDirectorySearchResults from './pages/bbbDirectorySearchResults';

const routes = [
  {
    path: ['/bbb-directory'],
    id: 'bbbdirectory',
    component: BbbDirectory,
  },
  {
    path: [`/bbb-directory/${COUNTRY_URL_SEGMENT}/:state`],
    id: 'bbbdirectory_searchresults',
    component: BbbDirectorySearchResults,
  },
];

export default routes;
