import * as Sentry from '@sentry/react';

import { defaultBrowserDenyUrls, defaultBrowserIgnoreErrors, dsn } from './constants';

function initSentry() {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    debug: process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true',
    dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration()],
    denyUrls: defaultBrowserDenyUrls,
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors: [
      ...defaultBrowserIgnoreErrors,
      // #region React 18 errors
      // The following 4 errors have to do with our upgrade to React 18.  We are ignoring them because we are maxing out our Sentry quota in a couple days.
      // Once we enable better source-code linking to the Sentry errors so that it is easier to debug, we will turn on each error at a time.
      /Hydration failed because the initial UI does not match what was rendered on the server/i,
      /There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering/i,
      /There was an error while hydrating this Suspense boundary. Switched to client rendering/i,
      /Text content does not match server-rendered HTML/i,
      /Minified React error #345/i, // Root did not complete. This is a bug in React.
      /Minified React error #418/i, // Hydration failed because the initial UI does not match what was rendered on the server.
      /Minified React error #419/i, // The server could not finish this Suspense boundary, likely due to an error during server rendering. Switched to client rendering.
      /Minified React error #422/i, // There was an error while hydrating this Suspense boundary. Switched to client rendering.
      /Minified React error #423/i, // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.
      /Minified React error #425/i, // Text content does not match server-rendered HTML.
      // #endregion
    ],

    // To send a representative sample of your errors to Sentry,
    // set the sampleRate option in your SDK configuration to a number
    // between 0 (0% of errors sent) and 1 (100% of errors sent).
    // This is a static rate, which will apply equally to all errors.
    sampleRate: process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true' ? 1 : 0.3,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    release:
      process.env.NEXT_PUBLIC_SENTRY_RELEASE !== 'uncategorized'
        ? process.env.NEXT_PUBLIC_SENTRY_RELEASE
        : undefined,
  });
}

export { initSentry };
