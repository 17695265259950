type EmptyProps = {
  error?: unknown;
};

const Empty = ({ error }: EmptyProps) => {
  if (error) {
    throw error;
  }
  return null;
};

export default Empty;
