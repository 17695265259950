import Button from '@iabbb/bds-react/Button';
import ErrorSummary, { FormErrorKey } from '@iabbb/bds-react/ErrorSummary';
import FieldTextarea from '@iabbb/bds-react/FieldTextarea';
import FieldTextInput from '@iabbb/bds-react/FieldTextInput';
import { getReCaptchaToken, RecaptchaProtectionText } from '@iabbb/shared/components/Form/Captcha';
import FullPageLoadingSpinner from '@iabbb/shared/components/General/FullPageLoadingSpinner';
import HoneypotField from '@iabbb/shared/components/General/HoneypotField';
import getIn from '@iabbb/utils/object/getIn';
import clsx from 'clsx';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { submitContactMessageAsync } from '../actions';
import { validateFormValues } from './utils';

function ContactForm({ additionalValues, setIsSubmitted }) {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const businessProfileUrl = useSelector((state: Record<string, object>) =>
    getIn(state, ['businessProfile', 'urls', 'profile']),
  );
  const bbbId = useSelector((state: Record<string, object>) => getIn(state, ['businessProfile', 'bbbId']));
  const businessId = useSelector((state: Record<string, object>) =>
    getIn(state, ['businessProfile', 'businessId']),
  );

  const onSubmit = async (values) => {
    // console.log(values);
    setValidationErrors(null);

    const errors = validateFormValues(values);

    if (Object.keys(errors).length) {
      setValidationErrors(errors);
      return errors;
    }

    setIsLoading(true);

    const recaptchaToken = await getReCaptchaToken();

    try {
      const contactData = {
        ...values,
        ...additionalValues,
        bbbId,
        businessId,
        businessProfileUrl,
        reCaptchaToken: recaptchaToken,
      };

      await dispatch(submitContactMessageAsync(contactData) as never);
      setIsLoading(false);
      setIsSubmitted(true);
    } catch (error) {
      setIsLoading(false);
      setValidationErrors({
        [FormErrorKey]: error as string,
      });
      return error;
    }
  };

  return (
    <>
      {isLoading && <FullPageLoadingSpinner style={{ '--stack-space': 0 } as React.CSSProperties} />}
      <ErrorSummary errors={validationErrors} />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => {
          return (
            <form className={clsx('stack', 'stack-space-24')} noValidate onSubmit={handleSubmit}>
              <div className={clsx('stack', 'stack-space-20')}>
                <Field name="firstName">
                  {({ input, meta }) => {
                    return (
                      <FieldTextInput
                        error={meta.submitError}
                        autoComplete="given-name"
                        label="First name"
                        {...input}
                      />
                    );
                  }}
                </Field>
                <Field name="lastName">
                  {({ input, meta }) => {
                    return (
                      <FieldTextInput
                        error={meta.submitError}
                        autoComplete="family-name"
                        label="Last name"
                        {...input}
                      />
                    );
                  }}
                </Field>
                <Field name="email">
                  {({ input, meta }) => {
                    return (
                      <FieldTextInput
                        error={meta.submitError}
                        autoComplete="email"
                        label="Email"
                        type="email"
                        {...input}
                      />
                    );
                  }}
                </Field>
                <Field name="message">
                  {({ input, meta }) => {
                    return (
                      <FieldTextarea
                        error={meta.submitError}
                        label="Message"
                        hint={
                          additionalValues.entityType
                            ? ''
                            : 'Provide specific details about your request so our team can quickly solve your issue.'
                        }
                        {...input}
                      />
                    );
                  }}
                </Field>
                <Field name="honeypot" type="radio" value="1">
                  {({ input }) => {
                    return <HoneypotField {...input} />;
                  }}
                </Field>
              </div>
              <div className={clsx('stack', 'stack-space-24')}>
                <RecaptchaProtectionText />
                <div style={{ textAlign: 'center' }}>
                  <Button preventDoubleClick type="submit">
                    Contact Us
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </>
  );
}

ContactForm.displayName = 'ContactForm';

export default ContactForm;
