import { produce, Draft } from 'immer';
import { ReducersMapObject, Action } from 'redux';

const combineImmerReducers = <S, A extends Action>(reducers: ReducersMapObject<S, A>) => {
  const keys = Object.keys(reducers);

  return produce((draft: Draft<S>, action: A) => {
    keys.forEach((key) => {
      draft[key] = reducers[key](draft[key], action);
    });
  }, {});
};

export default combineImmerReducers;
