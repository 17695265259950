import { css } from '@emotion/react';

export default css`
  // assigned element needs to have [role='switch'],
  // otherwise the [aria-checked] styling hooks don't make sense
  .toggle-button {
    --_offset: var(--offset, 0.25rem);
    --_width: var(--width, 3rem);

    align-items: center;
    background: transparent;
    border: none;
    border-radius: 99em;
    display: flex;
    outline: none;
    padding: var(--_offset) 0 var(--_offset) calc(var(--_width) + var(--bds-spacing-10));
    position: relative;

    /* Disabled buttons are not great; this matches the prior implementation, however. */
    &[disabled] {
      opacity: 0.5;
    }

    &:focus {
      &::before {
        outline: 2px solid var(--bds-color-blue-light);
        outline-offset: 3px;
      }
    }

    &::before {
      background: var(--bds-color-gray-60);
      border: none;
      border-radius: 99rem;
      content: '';
      height: 1.5rem;
      left: 0;
      position: absolute;
      width: var(--_width);
    }

    &::after {
      background: var(--bds-color-white);
      border-radius: 99rem;
      content: '';
      display: block;
      height: 1rem;
      left: var(--_offset);
      position: absolute;
      transition: all ease-in-out 0.15s;
      width: 1rem;
    }

    &[aria-checked='true'] {
      &::before {
        background: var(--bds-color-blue-medium);
      }

      &::after {
        left: calc(var(--_width) - var(--_offset));
        transform: translateX(-100%);
      }
    }

    @media screen and (-ms-high-contrast: active) {
      &:focus::before {
        outline: 1px dotted windowText;
      }

      &::after {
        background: windowText;
      }

      &[disabled]::after {
        background: transparent;
      }
    }
  }
`;
