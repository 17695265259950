import isEmail from '@iabbb/utils/form/validation/isEmail';
import isEmpty from '@iabbb/utils/form/validation/isEmpty';
import isNotHtml from '@iabbb/utils/form/validation/isNotHtml';
import { FormValues } from '../types';

export function validateFormValues(values: FormValues) {
  const errors: Partial<FormValues> = {};

  if (isEmpty(values.firstName)) {
    errors.firstName = 'Enter a first name';
  }

  if (isEmpty(values.lastName)) {
    errors.lastName = 'Enter a last name';
  }

  if (isEmpty(values.email)) {
    errors.email = `Enter an email address`;
  } else if (!isEmail(values.email)) {
    errors.email = 'Email must follow the correct format, like name@example.com';
  }

  if (isEmpty(values.message)) {
    errors.message = 'Enter a message';
  } else if (!isNotHtml(values.message)) {
    errors.message = 'Message must not include HTML tags';
  }

  return errors;
}
