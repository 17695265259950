import { COUNTRY_URL_SEGMENT, US_CA_COUNTRY_URL_SEGMENT } from '@iabbb/utils/routing/constants';

import GeoLanding from './pages/geoLanding';
import NearMe from './pages/nearMe';
import Content from './pages/content';
import CategoriesPage from './pages/categories';
import DynamicSeal from './pages/dynamicSeal';
import LeaveAReview from './pages/leaveAReview';
import LeaveAReviewUnlisted from './pages/leaveAReviewUnlisted';
import LeaveAReviewUnlistedThankYou from './pages/leaveAReviewUnlistedThankYou';
import ManageCookiesPage from './pages/manageCookies';
import ManageLocationPage from './pages/manageLocation';
import RequestAQuotePage from './pages/requestAQuote';

import localBbb from './localBbb';
import staticContent from './staticContent';
import content from './content';
import bbbDirectory from './bbbDirectory';
import fileAComplaint from './fileAComplaint';
import addBusiness from './addBusiness';
import { LazyExoticComponent } from 'react';

interface RoutesProps {
  id?: string;
  path: string[] | string;
  component: LazyExoticComponent<any> | React.ComponentType<unknown>;
}

const routes: RoutesProps[] = [
  {
    id: 'home',
    path: '/',
    component: GeoLanding,
  },
  {
    id: 'nearme_category',
    path: '/near-me/:category',
    component: NearMe,
  },
  {
    id: 'leave_a_review',
    path: '/leave-a-review',
    component: LeaveAReview,
  },
  {
    id: 'leave_a_review_no_bid',
    path: '/leave-a-review/no-bid',
    component: LeaveAReviewUnlisted,
  },
  {
    id: 'leave_a_review_no_bid_thank_you',
    path: '/leave-a-review/no-bid/thank-you',
    component: LeaveAReviewUnlistedThankYou,
  },
  {
    id: 'request_a_quote',
    path: '/get-a-quote',
    component: RequestAQuotePage,
  },
  {
    id: 'dynamic_seal',
    path: '/dynamic-seal',
    component: DynamicSeal,
  },
  ...staticContent,
  ...localBbb,
  ...content,
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/categories`,
      `/${US_CA_COUNTRY_URL_SEGMENT}/categories/:letter`,
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/categories`,
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/categories`,
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/categories/:letter`,
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/categories/:letter`,
    ],
    id: 'categories',
    component: CategoriesPage,
  },
  ...addBusiness,
  ...bbbDirectory,
  ...fileAComplaint,
  {
    path: [`/${COUNTRY_URL_SEGMENT}/:state/:city`, `/${COUNTRY_URL_SEGMENT}/:state`],
    id: 'home_culture_state_city',
    component: GeoLanding,
  },
  {
    id: 'manage_cookies',
    path: '/manage-cookies',
    component: ManageCookiesPage,
  },
  {
    id: 'manage_location',
    path: '/manage-location',
    component: ManageLocationPage,
  },
  {
    path: '*',
    id: 'fallback',
    component: Content,
  },
];

export default routes;
