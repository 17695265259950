export const dsn =
  process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true' || process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
    ? 'https://dc450e854f474eda9a1173e782ca24e6@o960626.ingest.sentry.io/5908977'
    : undefined;

export const defaultBrowserIgnoreErrors = [
  // Looks like an error encountered from ads, but ignoring for now. https://sentry.io/share/issue/a9768d7efa424a888ed112f1668255aa/
  'Error: s:0 rs:4',
  // LiveChat origin, which we can ignore for now until we implement Universal LiveChat
  'JSONP request failed',
  // this occurs (I believe) bc the user is referencing an old cached copy of a chunk
  'ChunkLoadError',
  // are probably caused by Sentry according to: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
  // probably related to buggy vendor api for file a complaint: https://sentry.io/share/issue/ec01d1de366f4bdb91364a9579acbc85/
  'Error: No error message',
  // Comes from a third-party salesforce script: https://iabbb.sentry.io/issues/5478200705/?project=5908977&query=is%3Aunresolved+issue.priority%3A%5Bhigh%2C+medium%5D&referrer=issue-stream&stream_index=4
  'Illegal invocation',

  // #region Plugin/browser extension based ignore errors: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  "Can't find variable: setIOSParameters",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Avast extension error
  '_avast_submit',
  //Google Analytics script error, occurs only on MacOS.
  'undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
  // #endregion
];

export const defaultBrowserDenyUrls = [
  // #region Plugin/browser extension based ignore errors: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // #endregion
];
