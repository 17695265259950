import Typography from '@iabbb/bds-react/Typography';
import Dialog from '@iabbb/shared/components/UI/Dialog';
import clsx from 'clsx';
import { useEffect, useId, useState, useMemo, useRef } from 'react';

import ContactForm from './ContactForm';
import { useContactDialog } from './context';

import './styles.css';

function DialogContactFormComponent() {
  const headingId = useId();
  const headingRef = useRef<HTMLDivElement>(null);

  const dialog = useContactDialog();

  const { toEmail, entityType, entityId, headingText, thankYouText } = dialog.state.props ?? {};

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const _heading = useMemo(
    () => (entityType ? `Email this ${entityType}` : (headingText ?? 'Email this BBB')),
    [entityType, headingText],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional - we want to focus the heading when submitted
  useEffect(() => {
    headingRef.current?.focus();
  }, [isSubmitted]);

  return (
    <Dialog
      data-is-submitted={isSubmitted ? '' : undefined}
      autoFocusDialogElement={false}
      className="dialog-contact-form"
      isOpen={dialog.state.open}
      close={() => {
        dialog.dispatch({ type: 'close' });
      }}
    >
      {toEmail && (
        <div className="stack">
          <Typography
            className={clsx('dialog-contact-form-heading', 'text-size-50')}
            component="h2"
            id={headingId}
            ref={headingRef}
            tabIndex={-1}
            variant="h3"
          >
            {!isSubmitted ? _heading : (thankYouText ?? 'Your message was successfully sent to this BBB')}
          </Typography>
          {!isSubmitted && (
            <ContactForm additionalValues={{ toEmail, entityType, entityId }} setIsSubmitted={setIsSubmitted} />
          )}
        </div>
      )}
    </Dialog>
  );
}

export default DialogContactFormComponent;
