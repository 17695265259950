import header from '@iabbb/shared/containers/Header/reducer';
import global from '@iabbb/shared/states/Global/reducer';
import debuggerReducer from '@iabbb/shared/components/General/Debugger/reducer';
import user from '@iabbb/shared/states/User/reducer';
import analytics from '@iabbb/shared/states/Analytics/reducer';
import page from '@iabbb/shared/components/General/Page/reducer';
import combineImmerReducers from '@iabbb/context/Redux/combineImmerReducers';
import reduceReducers from '@iabbb/context/Redux/reduceReducers';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createRootReducer = ({ injectedReducers = {} } = {}) => {
  const reducer = combineImmerReducers({
    analytics,
    user,
    global,
    header,
    page,
    ...injectedReducers,
  });

  return reduceReducers(reducer, debuggerReducer);
};

export default createRootReducer;
