import FileAComplaintForm from './pages/fileAComplaintForm';
import FileAComplaintFormLanding from './pages/fileAComplaintFormLanding';
import FileAComplaintLanding from './pages/fileAComplaintLanding';
import FileAComplaintBusinessLanding from './pages/fileAComplaintBusinessLanding';
import FileAComplaintSearch from './pages/fileAComplaintSearch';

const routes = [
  {
    id: 'file_a_complaint_landing',
    path: '/file-a-complaint',
    component: FileAComplaintLanding,
  },
  {
    id: 'file_a_complaint_search',
    path: '/file-a-complaint/search',
    component: FileAComplaintSearch,
  },
  {
    id: 'file_a_complaint_landing',
    path: '/file-a-complaint/:id',
    component: FileAComplaintBusinessLanding,
  },
  {
    id: 'file_a_complaint_form_landing',
    path: '/file-a-complaint/:id/form',
    component: FileAComplaintFormLanding,
  },
  {
    id: 'file_a_complaint_form',
    path: '/file-a-complaint/:id/form/:complaintId',
    component: FileAComplaintForm,
  },
];

export default routes;
