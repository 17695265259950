import ContentList from './pages/contentList';
import People from './pages/localBbbPeople';
import PeopleGroups from './pages/localBbbPeopleGroups';
import Supporters from './pages/localBbbSupporters';
import Overview from './pages/localBbbOverview';

const routes = [
  {
    path: '/local-bbb/:bbbId',
    id: 'localbbb_overview',
    component: Overview,
  },
  {
    path: [
      '/local-bbb/:bbbName/news',
      '/local-bbb/:bbbName/events',
      '/local-bbb/:bbbName/news-and-events',
      '/local-bbb/:bbbName/news/:topic',
      '/local-bbb/:bbbName/events/:topic',
    ],
    id: 'localbbb_contentlist',
    component: ContentList,
  },
  {
    path: '/local-bbb/:bbbName/people',
    id: 'localbbb_people_groups',
    component: PeopleGroups,
  },
  {
    path: '/local-bbb/:bbbName/people/:peopleGroupName',
    id: 'localbbb_people',
    component: People,
  },
  {
    path: '/local-bbb/:bbbName/supporters',
    id: 'localbbb_supporters',
    component: Supporters,
  },
];

export default routes;
