import { Action, Reducer } from 'redux';
/**
 * Used just like combineReducers, except creates a flat, rather then nested reducer.  Use sparingly.
 */
const reduceReducers = <S, A extends Action>(...args: Reducer<S, A>[]) => {
  const initialState = typeof args[args.length - 1] !== 'function' && args.pop();
  const reducers = args;

  if (typeof initialState === 'undefined') {
    throw new TypeError(
      'The initial state may not be undefined. If you do not want to set a value for this reducer, you can use null instead of undefined.',
    );
  }

  return (prevState: S, value: A) =>
    typeof prevState === 'undefined'
      ? initialState
      : reducers.reduce((newState, reducer) => reducer(newState, value), prevState);
};

export default reduceReducers;
