import { css } from '@emotion/react';

export default css`
  .form-field {
    > label {
      font-weight: var(--bds-font-weight-bold);
    }

    .hint {
      --stack-space: 0.125rem;
    }

    .typeahead {
      --stack-space: 0.3125rem;
      border: 1px solid var(--bds-color-gray-70);

      &.has-error {
        border: 2px solid var(--bds-color-red-light);

        &:focus-within {
          /* 👇 revert to default border; otherwise, the red/focus outline clash */
          border: 1px solid var(--bds-color-gray-70);
        }
      }

      &:focus-within {
        /* 👇 double focus ring: 3px offset means the ring has a 1px width */
        box-shadow: 0 0 0 3px var(--bds-color-white);
        outline: 2px solid var(--bds-color-purple-dark);
      }

      input {
        border: none;
        min-block-size: 2.8125rem;

        &:focus {
          box-shadow: none;
          outline: none;
        }

        &[aria-invalid='true'] {
          border: none;

          &:focus {
            border: none;
          }
        }
      }
    }
  }
`;
