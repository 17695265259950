declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface RecaptchaProtectionTextProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
}

export const getReCaptchaToken = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' })
        .then(resolve, reject);
    });
  });
};

export function RecaptchaProtectionText({ style, ...props }: RecaptchaProtectionTextProps) {
  return (
    <div {...props} style={{ textAlign: 'center', ...style }}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  );
}
