// TODO: DISABLE WHEN NOT DEBUGGING
// const DEBUG = false;

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export async function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const text = await response.text();
  if (text) {
    return JSON.parse(text);
  }
  return null;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */

export function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = Object.assign(new Error(response.statusText), {
      status: response.statusText,
      response,
    });
    throw error;
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request<T>(url: string, options?: T) {
  // if (DEBUG) console.info('REQUEST ====> ', url);
  return fetch(url, options).then(checkStatus).then(parseJSON);
}
