import { sendMessage } from '@iabbb/utils/api/ContactApi';
import { ContactParams } from './types';
/**
 * Maps and manipulates react-final-form values to fit into contactParams structure.
 *
 * @param {array} values - Array of values from react-final-form.
 */
function mapValuesToContactParams(values: ContactParams) {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    message: values.message,
    fromEmail: values.email,
    toEmail: values.toEmail,
    reCaptchaToken: values.reCaptchaToken,
    entityType: values.entityType,
    entityId: values.entityId,
    bbbId: values.bbbId,
    businessId: values.businessId,
    businessProfileUrl: values.businessProfileUrl,
    honeypot: values.honeypot,
  };
}

/**
 * Takes contact params and pushes them to API endpoint that uses them to send an email message
 *
 * @param {array} values - Array of values from react-final-form.
 */
export function submitContactMessageAsync(values: ContactParams) {
  return async (_dispatch) => {
    const contactParams = mapValuesToContactParams(values);
    try {
      await sendMessage(contactParams);
    } catch (err) {
      const error = err as { response?: { status: number } };
      if (error.response?.status === 403) {
        return Promise.reject(
          'Your message has been sent to the business.  If you’d like to reach out to this business again, please contact them directly.',
        );
      }
      // too many requests
      else if (error.response?.status === 429) {
        return Promise.reject(
          "You've reached the limit for submissions today. If you'd like to reach out to this business again, please contact them directly.",
        );
      } else {
        return Promise.reject('Email could not be sent. Please try again later.');
      }
    }
  };
}
