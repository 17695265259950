import AddBusinessLanding from './pages/addBusinessLanding';
import AddBusinessFormLanding from './pages/addBusinessFormLanding';
import AddBusinessFormConsumer from './pages/addBusinessFormConsumer';
import AddBusinessFormBusiness from './pages/addBusinessFormBusiness';
import AddBusinessSuccess from './pages/addBusinessSuccess';

const routes = [
  {
    id: 'get_listed_landing',
    path: '/get-listed',
    component: AddBusinessLanding,
  },
  {
    id: 'get_listed_form_landing',
    path: '/get-listed/form',
    component: AddBusinessFormLanding,
  },
  {
    id: 'get_listed_form_consumer',
    path: '/get-listed/consumer',
    component: AddBusinessFormConsumer,
  },
  {
    id: 'get_listed_form_business',
    path: '/get-listed/business',
    component: AddBusinessFormBusiness,
  },
  {
    id: 'get_listed_success',
    path: '/get-listed/success',
    component: AddBusinessSuccess,
  },
];

export default routes;
