import { createContext, useContext, useReducer } from 'react';

type DialogProps = {
  entityId?: string;
  entityType?: string;
  headingText?: string;
  thankYouText?: string;
  toEmail: string;
};

type Action = { type: 'close' } | { type: 'open'; props: DialogProps };
type Dispatch = (action: Action) => void;
type State = { open: boolean; props: DialogProps | null };
type ContactDialogProviderProps = { children: React.ReactNode };

const ContactDialogStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function dialogReducer(_state: State, action: Action) {
  switch (action.type) {
    case 'open': {
      return { open: true, props: action.props };
    }
    case 'close': {
      return { open: false, props: null };
    }
    default: {
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`);
    }
  }
}

function ContactDialogProvider({ children }: ContactDialogProviderProps) {
  const [state, dispatch] = useReducer(dialogReducer, { open: false, props: null });
  const value = { state, dispatch };
  return <ContactDialogStateContext.Provider value={value}>{children}</ContactDialogStateContext.Provider>;
}

function useContactDialog() {
  const context = useContext(ContactDialogStateContext);

  if (context === undefined) {
    throw new Error('useContactDialog must be used within a ContactDialogProvider');
  }

  return context;
}

export { ContactDialogProvider, useContactDialog };
