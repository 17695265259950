import urljoin from 'url-join';

import { API_BASE_URL } from '@iabbb/utils/api/constants';
import { SendMessageParams } from './types';
import request from '@iabbb/utils/api/request';

const buildApiUrl = (...segments: string[]) =>
  urljoin(process.env.NEXT_PUBLIC_BASE_WEB_API_URL, API_BASE_URL, 'contact', ...segments);

const createFetchPostOptions = (data: SendMessageParams) => ({
  method: 'POST',
  body: JSON.stringify(data),
  headers: new Headers({ 'Content-Type': 'application/json' }),
});

const sendMessage = async (data: SendMessageParams) => {
  const url = buildApiUrl('/sendmessage');
  const fetchOptions = createFetchPostOptions(data);
  return request(url, fetchOptions);
};

export { sendMessage };
